import { debounce, once } from 'lodash';
import uri from 'urijs';
import { createStore, applyMiddleware, bindActionCreators } from 'redux';
import { composeWithDevTools } from 'remote-redux-devtools';
import thunk from 'redux-thunk';
import throttleActions from 'redux-throttle';
import debounceActions from '@sarunas/redux-debounce';
import * as Raven from 'raven-js';
import wixWs from '@wix/wix-ws-client/dist/ws-client.es5';
import { EXPERIMENT_USE_DUPLEXER } from '../constants/experiments';
import { SANTA_MEMBERS_APP_ID, CHAT_APP_DEF_ID } from '../constants/applications';
import {
  CREATE_COMMENT,
  CREATE_POST,
  DELETE_COMMENT,
  DELETE_POST,
  LIKE_COMMENT,
  LIKE_POST,
  UPDATE_COMMENT,
  UPDATE_POST,
} from '../constants/interactions';
import { DELETE_POST_SUCCESS } from '../actions/delete-post';
import { DELETE_COMMENT_SUCCESS } from '../actions/delete-comment';
import createRequest from '../../common/services/create-request';
import { fetchAllCategories, fetchSubcategories } from '../../common/actions/fetch-categories';
import { addTopCommentAndNotify } from '../actions/add-top-comment';
import { removeTopCommentAndNotify } from '../actions/remove-top-comment';
import { startEditingComment } from '../actions/start-editing-comment';
import { fetchCategoryPosts } from '../actions/fetch-category-posts';
import { setPostTypeFilter } from '../actions/set-filter';
import { subscribeToCategory } from '../actions/subscribe-to-category';
import { unsubscribeFromCategory } from '../actions/unsubscribe-from-category';
import { resolveCommentDeepLinkUrlPromisified } from '../actions/comment-deep-link-actions';
import { fetchForumData } from '../actions/fetch-forum-data';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { showJoinCommunityPopup } from '../../common/actions/join-community';
import { pageOpened } from '../actions/page-opened';
import {
  isMaInstalled,
  setCommunitiesContext,
} from '../../common/store/communities-context/communities-context-actions';
import { setBasicParams } from '../../common/store/basic-params/basic-params-actions';
import { setStyleParams } from '../../common/store/style-params/style-params-actions';
import { fetchExperiments } from '../../common/actions/fetch-experiments';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import { fetchUser } from '../../common/actions/fetch-user';
import { getFromMemoryCache, saveInMemoryCache } from '../../common/services/memory-cache';
import { fetchTranslations } from '../../common/store/translations/translations-actions';
import {
  createDuplexerConnection,
  createDuplexerMiddleware,
  socketEventHandlers,
} from '../middleware/duplexer-middleware';
import noopMiddleware from '../middleware/noop-middleware';
import rootReducer from '../reducers';
import {
  createModalClosedMiddleware,
  createModalOpenedMiddleware,
  modalActions,
} from '../../common/modals/framework/initialize-store';
import { modalResolverMapByType } from '../components/modals/modal-resolver-map-by-type';
import { modalDataLoaderByType } from '../components/modals/modal-data-loader-map-by-type';
import { setRouterMatch } from '../../common/router';
import { transformWixCodeLocation } from '../../common/store/location/transform-wix-code-location';
import initMiddlewareForExperiment from '../services/init-middleware-for-experiment';
import { createRouter } from './create-router';
import search, { clearSearchResults } from '../actions/search';
import {
  incrementCommentLikeCount,
  INCREMENT_COMMENT_LIKE_COUNT_SUCCESS,
} from '../actions/increment-comment-like-count';
import {
  incrementPostLikeCount,
  INCREMENT_POST_LIKE_COUNT_SUCCESS,
} from '../../common/actions/increment-post-like-count';
import {
  messageActions,
  createMessageMiddleware,
} from '../../common/messages/framework/initialize-store';
import messageResolvers from '../components/messages/message-resolvers';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { categoriesManagerApi } from '../actions/categories-manager-actions';
import createShowMessage from '../../common/messages/framework/create-show-message';
import { uploadImageCategoriesManager, uploadImageRCE } from '../actions/image-upload/upload-image';
import { fetchPostPageData } from '../actions/fetch-post-page-data';
import { incrementPostViewCount } from '../actions/increment-post-view-count';
import { setPostsPageSorting } from '../actions/set-sorting';
import { setIsLoaded } from '../actions/set-is-loaded';
import { setIsLoading } from '../actions/set-is-loading';
import { fetchRceTranslations } from '../actions/fetch-rce-translations';
import { subscribeToPost } from '../actions/subscribe-to-post';
import { unsubscribeFromPost } from '../actions/unsubscribe-from-post';
import { pinPost } from '../actions/pin-post';
import { unpinPost } from '../actions/unpin-post';
import { enablePostComments } from '../actions/enable-post-comments';
import { disablePostComments } from '../actions/disable-post-comments';
import { actionsOpened } from '../actions/actions-opened';
import { actionsClosed } from '../actions/actions-closed';
import { buttonClicked } from '../actions/button-clicked';
import { emitOpenCategory, emitCloseCategory } from '../actions/category-socket';
import { emitForumOpened } from '../actions/forum-socket';
import {
  emitTypingThrottled,
  flushTypingThrottled,
  clearTypingUsers,
} from '../actions/typing-socket';
import { emitClosePost, emitOpenPost } from '../actions/post-socket';
import { removeItemFromUploadedRegistry } from '../actions/uploaded-registry-actions';
import { fetchOembed } from '../actions/fetch-oembed';
import { appLoaded } from '../actions/app-loaded';
import { createPostAndNavigateToItPromisified, CREATE_POST_SUCCESS } from '../actions/create-post';
import { updatePostPromisified, UPDATE_POST_SUCCESS } from '../actions/update-post';
import {
  createComment,
  createCommentReply,
  createCommentPromisified,
  createCommentReplyPromisified,
  CREATE_COMMENT_SUCCESS,
} from '../actions/create-comment';
import {
  fetchReplies,
  fetchInitialReplies,
  fetchRepliesToPagePromisified,
} from '../actions/fetch-replies';
import { updateCommentPromisified, UPDATE_COMMENT_SUCCESS } from '../actions/update-comment';
import { stopEditingComment } from '../actions/stop-editing-comment';
import { fetchCommentsWithReplies } from '../actions/fetch-comments';
import { upvoteComment, downvoteComment, unvoteComment } from '../actions/vote-comment';
import { changePage } from '../actions/change-page';
import { goBack } from '../actions/go-back';
import { clearComments } from '../actions/clear-comments';
import { initializeLocation } from '../../common/store/location/location-actions';
import { initializeMaNavigation } from '../../common/store/ma-navigation/ma-navigation-actions';
import { getCurrentUser } from '../../common/store/current-user/current-user-selectors';
import { parseInstance } from '../../common/store/instance-values/parse-instance';
import { addErrorState, addDebugState } from '../../common/store/debug-state/debug-state-actions';
import { handleError } from '../../common/store/debug-state/handle-error';
import createInteractionsMiddleware from '../../common/interactions/create-interactions-middleware';
import createWebsocketMiddleware from '../middleware/create-websocket-middleware';
import { initialiseInstanceValues } from '../../common/store/instance-values/instance-values-actions';
import { forumsUouBiMiddleware, forumsUsersBiMiddleware } from '../bi-events/init-middleware';
import { FORUM_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import listenToSettingsChange from '../services/listen-to-settings-change';
import createRouterMiddleware from '../middleware/create-router-middleware';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { fastFormSubmitCallback } from '../../common/components/fast-form/store/fast-form-actions';
import { createFastFormSubmitMiddleware } from '../../common/components/fast-form/store/fast-form-submit-middleware';
import { createNoopController } from '../../viewer-script/controllers/createNoopController';
import { setSearchPageSorting } from '../../../app/actions/set-sorting';
import { setHasReportedAppLoaded } from '../../common/store/app-loaded/app-loaded-actions';
import { validateLanguage } from '../../common/services/validate-language';
import { SENTRY_VIEWER_SCRIPT_DSN } from '../../common/constants/sentry-viewer-dsn';
import { fetchBadges } from '../actions/fetch-badges';
import { fetchPaidPlans } from '../actions/fetch-paid-plans';
import {
  requestCredentialsPromisified,
  requestCredentialsV2Promisified,
  requestFileDownloadUrlPromisified,
} from '../../common/services/wix-media/media-api';
import { fileDownloadRequest, fileUploaded } from '../actions/file-upload-bi-actions';
import {
  userEventsMakeCategoryChildOrParent,
  userEventsShowEmptyState,
  userEventsReplyOrCommentIntent,
  userEventsClickPublish,
} from '../actions/user-events';
import { requestLoginPromisified, requestLogin } from '../actions/request-login';
import { setSavedAction } from '../../common/store/saved-action/set-saved-action';
import { invokeSavedAction } from '../services/invoke-saved-action';
import { getLocation } from '../../common/store/location/location-selectors';
import {
  manageCategoryMembersAddSiteMembers,
  manageCategoryMembersRemoveSiteMembers,
} from '../actions/manage-category-members';
import { categoryAddGroups, categoryRemoveGroups } from '../actions/private-category-actions';
import {
  fetchSiteMembersList,
  resetSiteMembersList,
} from '../../common/store/site-members/site-members-actions';
import { assignMembersToGroup } from '../actions/assign-members-to-group';
import { deleteMembersFromGroup } from '../actions/delete-members-from-group';
import {
  fetchMembersGroupsList,
  fetchMembersGroupsListPromisified,
} from '../actions/fetch-members-groups-list';
import listenToEditModeChange from '../services/listen-to-edit-mode-change';

const MEMORY_STORAGE_KEY = 'forum-app:memory';
const isProduction = process.env.NODE_ENV === 'production';
let isControllerInitialized = false;

export const createForumAppController = (
  { appParams, compId, config, setProps, wixCodeApi, platformAPIs, type },
  allCtrls,
) => {
  const isEditor = wixCodeApi.window.viewMode === 'Editor';
  const isPreview = wixCodeApi.window.viewMode === 'Preview';
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const isDebug = wixCodeApi.location.query.debug;

  // there is a bug in viewerScriptWrapper where it initialized controller again when going into preview
  if ((isEditor || isPreview) && isControllerInitialized) {
    return createNoopController();
  } else {
    isControllerInitialized = true;
  }

  const { fedOpsLoggerFactory } = platformAPIs;

  const sentry = new Raven.Client();
  sentry.config(SENTRY_VIEWER_SCRIPT_DSN, {
    dataCallback: data => {
      data.environment = isSSR ? 'App-Worker-SSR' : 'App-Worker-CSR';
      return data;
    },
  });

  const fedopsLogger = fedOpsLoggerFactory.getLoggerForWidget({
    appId: appParams.appDefinitionId,
    widgetId: type,
  });
  fedopsLogger.appLoadStarted();
  const fedopsAppLoaded = once(() => fedopsLogger.appLoaded());

  const initialLoadPromises = [];
  const memoryCachedStore = getFromMemoryCache(platformAPIs, MEMORY_STORAGE_KEY);
  const language = validateLanguage(wixCodeApi.site.language);

  const log = createLogger(isDebug, isProduction);

  log('createForumAppController', {
    appParams,
    allCtrls,
    wixCodeApi,
    isSSR,
    language,
    memoryCachedStore,
  });

  const pageReady = () => {
    log('createForumAppController.pageReady -> start');

    return Promise.resolve(wixCodeApi.site.getSectionUrl({ sectionId: FORUM_SECTION_ID }))
      .then(({ url: sectionUrl }) => {
        let router;
        const getRouter = () => router;
        const store = createReduxStore({
          appParams,
          memoryCachedStore,
          wixCodeApi,
          compId,
          reducers: rootReducer,
          isDebug,
          fedopsLogger,
          getRouter,
          platformAPIs,
          language,
          isSSR,
          sentry,
          isEditor,
          isPreview,
          config,
        });
        const actions = initializeActions({
          wixCodeApi,
          store,
          fedopsLogger,
          fedopsAppLoaded,
        });
        const actionsPromisified = initializePromisifiedActions({ wixCodeApi, compId, store });
        const location = transformWixCodeLocation(wixCodeApi.location, sectionUrl);
        router = createRouter(store, config, wixCodeApi, compId);
        const viewMode = wixCodeApi.window.viewMode.toLowerCase();

        store.dispatch(initializeLocation());

        if (!memoryCachedStore) {
          initialLoadPromises.push(
            initializeStoreBaseData({
              memoryCachedStore,
              wixCodeApi,
              store,
              viewMode,
              language,
              platformAPIs,
              config,
            }),
          );
        }

        if (wixCodeApi.location.onChange) {
          wixCodeApi.location.onChange(({ path }) => {
            log('navigated to new path', path);
            store.dispatch(initializeLocation());
            doRouting({
              store,
              router,
              location: transformWixCodeLocation(wixCodeApi.location, sectionUrl),
              isInitialLoad: false,
            });
          });
        }

        return Promise.all(initialLoadPromises)
          .then(() => {
            const promise = doRouting({ store, router, location, isInitialLoad: true });
            if (!memoryCachedStore) {
              return promise;
            }
          })
          .then(() => {
            log('createForumAppController.pageReady -> done');
            const state = store.getState();

            // after initial routing is done, we subscribe to get routing change as fast as route changes, without waiting for handler to resolve
            router.onMatch(match => store.dispatch(setRouterMatch(match)));

            setProps({
              state,
              actions,
              actionsPromisified,
              cssBaseUrl: appParams.baseUrls.staticsBaseUrl,
              isRTL: isRtlLanguage(language),
              isSSR,
            });

            if (isSSR) {
              fedopsAppLoaded();
            }

            if (isEditor || isPreview) {
              listenToEditModeChange(store);
              listenToSettingsChange(store, true, () => doRouting({ store, router, location }));
            }

            if (isEditor || isPreview) {
              initializeNavigationHandlerForEditor({ store, router });
            }

            refreshDataOnLogin({ wixCodeApi, store, router });
            subscribeToChange(store, stateDiff => setProps({ state: stateDiff }));
            saveInMemoryCache(platformAPIs, MEMORY_STORAGE_KEY, filterStateForCache(state));
            updateLocalCacheOnStateChange({ platformAPIs, store });
          })
          .catch(
            handleError({
              store,
              setProps,
              appParams,
              captureToSentry: e => sentry.captureException(e),
            }),
          );
      })
      .catch(
        handleError({ setProps, appParams, captureToSentry: e => sentry.captureException(e) }),
      );
  };

  return Promise.resolve({
    pageReady: () => {
      if (wixCodeApi.window.viewMode === 'Editor') {
        return handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady);
      }
      return pageReady();
    },
    exports: () => ({}),
  }).catch(console.error);
};

function createReduxStore({
  memoryCachedStore,
  reducers,
  appParams,
  wixCodeApi,
  compId,
  isDebug,
  fedopsLogger,
  platformAPIs,
  getRouter,
  language,
  isSSR,
  sentry,
  isEditor,
  isPreview,
  config,
}) {
  const p = {};
  const getInstance = () => wixCodeApi.user.currentUser.instance;

  function createRequestWithBaseUrl(baseUrl) {
    return createRequest({
      baseUrl,
      getInstance,
      locale: language,
      trackError: error => p.store.dispatch(addErrorState(error)),
      logResponse: isDebug ? response => p.store.dispatch(addDebugState(response)) : undefined,
      petriOvr: wixCodeApi.location.query.petri_ovr,
      siteRevision: wixCodeApi.site.revision,
    });
  }

  const origin = uri(wixCodeApi.location.baseUrl).origin();
  const baseUrls = appParams.baseUrls;

  const baseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiBaseUrlClient}`
      : baseUrls.apiBaseUrlClient;
  const badgesApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiBadgesBaseUrlClient}`
      : baseUrls.apiBadgesBaseUrlClient;
  const membersGroupsApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiMembersGroupsBaseUrlClient}`
      : baseUrls.apiMembersGroupsBaseUrlClient;
  const siteMembersApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiSiteMembersBaseUrlClient}`
      : baseUrls.apiSiteMembersBaseUrlClient;
  const paidPlansApiBaseUrl =
    wixCodeApi.window.rendering.env === 'backend'
      ? `${origin}${baseUrls.apiPaidPlansBaseUrlClient}`
      : baseUrls.apiPaidPlansBaseUrlClient;
  const request = createRequestWithBaseUrl(baseUrl);
  const badgesRequest = createRequestWithBaseUrl(badgesApiBaseUrl);
  const membersGroupsRequest = createRequestWithBaseUrl(membersGroupsApiBaseUrl);
  const siteMembersRequest = createRequestWithBaseUrl(siteMembersApiBaseUrl);
  const paidPlansRequest = createRequestWithBaseUrl(paidPlansApiBaseUrl);

  p.store = createStore(
    reducers,
    memoryCachedStore ? memoryCachedStore : undefined,
    composeWithReduxDevTools(wixCodeApi)(
      applyMiddleware(
        throttleActions(),
        debounceActions(),
        thunk.withExtraArgument({
          request,
          badgesRequest,
          membersGroupsRequest,
          siteMembersRequest,
          paidPlansRequest,
          wixCodeApi,
          compId,
          appParams,
          fedopsLogger,
          getRouter,
        }),
        createFastFormSubmitMiddleware({}),
        createRouterMiddleware(getRouter),
        createModalClosedMiddleware({ modalResolverMapByType, createShowMessage }),
        createModalOpenedMiddleware({ modalDataLoaderByType }),
        createMessageMiddleware({ resolvers: messageResolvers }),
        createInteractionsMiddleware(fedopsLogger, {
          [CREATE_POST_SUCCESS]: CREATE_POST,
          [UPDATE_POST_SUCCESS]: UPDATE_POST,
          [INCREMENT_POST_LIKE_COUNT_SUCCESS]: LIKE_POST,
          [DELETE_POST_SUCCESS]: DELETE_POST,
          [CREATE_COMMENT_SUCCESS]: CREATE_COMMENT,
          [UPDATE_COMMENT_SUCCESS]: UPDATE_COMMENT,
          [INCREMENT_COMMENT_LIKE_COUNT_SUCCESS]: LIKE_COMMENT,
          [DELETE_COMMENT_SUCCESS]: DELETE_COMMENT,
        }),
        forumsUouBiMiddleware(platformAPIs, appParams.instanceId, wixCodeApi, config),
        forumsUsersBiMiddleware(platformAPIs, appParams.instanceId, wixCodeApi, config),
        initMiddlewareForExperiment({
          experiment: EXPERIMENT_USE_DUPLEXER,
          expectedExperimentValue: false,
          delayedActionRegExp: /^ws\//,
          initMiddleware: () => {
            if (isSSR || isEditor) {
              return noopMiddleware;
            }

            const wsClient = request('/ws-token')
              .then(tokenRes => {
                return wixWs({ wsUrl: tokenRes.url, token: tokenRes.token });
              })
              .catch(e => console.error('Failed to create ws client', e));

            return createWebsocketMiddleware(wsClient, appParams.instanceId);
          },
        }),
        initMiddlewareForExperiment({
          experiment: EXPERIMENT_USE_DUPLEXER,
          expectedExperimentValue: true,
          delayedActionRegExp: /^duplexer\//,
          initMiddleware: () => {
            if (isSSR || isEditor || isPreview) {
              return noopMiddleware;
            }

            return createDuplexerMiddleware({
              duplexerConnection: createDuplexerConnection({
                duplexerSocketsServerUrl: `apps.wix.com/wix-duplexer-sockets-server`,
                appDefId: appParams.appDefinitionId,
                getInstance,
              }),
              eventHandlers: socketEventHandlers,
              onError: error => sentry.captureException(error),
            });
          },
        }),
      ),
    ),
  );

  return p.store;
}

export function initializeActions({ wixCodeApi, store, fedopsLogger, fedopsAppLoaded }) {
  return {
    interactionStarted: interaction => fedopsLogger.interactionStarted(interaction),
    interactionEnded: interaction => fedopsLogger.interactionEnded(interaction),
    startPrivateChat: uid =>
      wixCodeApi.site.getPublicAPI(CHAT_APP_DEF_ID).then(api => api.startPrivateChat(uid)),
    navigateToSection: section =>
      wixCodeApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then(api => api.navigateToSection(section)),
    navigateToMember: memberId =>
      wixCodeApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then(api => api.navigateToMember(memberId)),
    logout: () => wixCodeApi.user.logout(),
    navigateToRoot: () => {
      wixCodeApi.location.to('/');
    },
    navigateToProfile: siteMemberIdOrSlug => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        api.navigateToMember({ memberId: siteMemberIdOrSlug }).catch(console.error);
      });
    },
    navigateToProfileSettings: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        const user = getCurrentUser(store.getState());
        api
          .navigateToSection({
            appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
            sectionId: 'settings',
            memberId: user.siteMemberId,
          })
          .catch(console.error);
      });
    },
    navigateToNotifications: () => {
      wixCodeApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then(api => {
        api
          .navigateToSection({
            appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
            sectionId: 'notifications',
          })
          .catch(console.error);
      });
    },
    renderSEOTags: data => wixCodeApi.seo.renderSEOTags(data),
    ...bindActionCreators(
      {
        appLoaded: once(appLoaded(fedopsAppLoaded)),
        addTopComment: addTopCommentAndNotify,
        removeTopComment: removeTopCommentAndNotify,
        showJoinCommunityPopup,
        search,
        incrementCommentLikeCount,
        incrementPostLikeCount,
        pageOpened,
        navigateWithinForum,
        uploadImageCategoriesManager,
        uploadImageRCE,
        fetchPostPageData,
        incrementPostViewCount,
        setIsLoaded,
        setIsLoading,
        fetchCategoryPosts,
        fetchSubcategories,
        subscribeToCategory,
        unsubscribeFromCategory,
        subscribeToPost,
        unsubscribeFromPost,
        pinPost,
        unpinPost,
        enablePostComments,
        disablePostComments,
        actionsOpened,
        actionsClosed,
        buttonClicked,
        removeItemFromUploadedRegistry,
        fetchOembed,
        createComment,
        createCommentReply,
        changePage,
        setPostsPageSorting,
        fetchReplies,
        fetchInitialReplies,
        startEditingComment,
        stopEditingComment,
        fetchCommentsWithReplies,
        clearComments,
        goBack,
        emitOpenCategory,
        emitCloseCategory,
        emitTypingThrottled,
        flushTypingThrottled,
        clearTypingUsers,
        emitClosePost,
        emitOpenPost,
        fastFormSubmitCallback,
        setSearchPageSorting,
        setHasReportedAppLoaded,
        fileDownloadRequest,
        fileUploaded,
        userEventsMakeCategoryChildOrParent,
        userEventsShowEmptyState,
        userEventsReplyOrCommentIntent,
        userEventsClickPublish,
        requestLogin,
        setSavedAction,
        ...categoriesManagerApi,
        ...modalActions,
        ...messageActions,
        manageCategoryMembersRemoveSiteMembers,
        manageCategoryMembersAddSiteMembers,
        assignMembersToGroup,
        deleteMembersFromGroup,
        fetchSiteMembersList,
        resetSiteMembersList,
        fetchMembersGroupsList,
        fetchBadges,
        fetchPaidPlans,
        categoryAddGroups,
        categoryRemoveGroups,
        setPostTypeFilter,
        clearSearchResults,
        upvoteComment,
        downvoteComment,
        unvoteComment,
      },
      store.dispatch,
    ),
  };
}

export function initializePromisifiedActions({ store }) {
  return {
    ...bindActionCreators(
      {
        createCommentPromisified,
        createCommentReplyPromisified,
        fetchRepliesToPagePromisified,
        resolveCommentDeepLinkUrlPromisified,
        createPostAndNavigateToItPromisified,
        updateCommentPromisified,
        updatePostPromisified,
        requestCredentialsPromisified,
        requestCredentialsV2Promisified,
        requestFileDownloadUrlPromisified,
        requestLoginPromisified,
        fetchMembersGroupsListPromisified,
      },
      store.dispatch,
    ),
  };
}

function initializeStoreBaseData({ wixCodeApi, store, viewMode, language, platformAPIs, config }) {
  const promises = [
    initUserDependentStoreBaseData({ store, wixCodeApi }),
    isMaInstalled(wixCodeApi).then(isInstalled => {
      if (isInstalled) {
        store.dispatch(initializeMaNavigation());
      }
    }),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setBasicParams({ viewMode, language, pageNumber: platformAPIs.bi.pageNumber })),
    store.dispatch(setStyleParams(config.style.styleParams)),
    store.dispatch(fetchTranslations(language)),
    store.dispatch(fetchRceTranslations(language)),
    store.dispatch(emitForumOpened()),
  ];
  return Promise.all(promises);
}

function initUserDependentStoreBaseData({ store, wixCodeApi }) {
  const user = wixCodeApi.user.currentUser;
  const { biToken } = parseInstance(wixCodeApi.user.currentUser.instance);
  const isSiteSaved = biToken !== undefined;
  return Promise.all([
    user.loggedIn && isSiteSaved
      ? store.dispatch(fetchUser({ siteMemberId: wixCodeApi.user.currentUser.id }))
      : Promise.resolve(),
    store.dispatch(fetchForumData()),
    store.dispatch(fetchAllCategories()),
    isSiteSaved ? store.dispatch(fetchBadges()) : Promise.resolve(),
    store.dispatch(fetchExperiments()),
    store.dispatch(initialiseInstanceValues(wixCodeApi.user.currentUser.instance)),
  ]);
}

function refreshDataOnLogin({ wixCodeApi, store, router }) {
  wixCodeApi.user.onLogin(() => {
    setTimeout(() => {
      initUserDependentStoreBaseData({ store, wixCodeApi })
        .then(() => doRouting({ store, router, location: getLocation(store.getState()) }))
        .then(() => invokeSavedAction(store));
    });
  });
}

function updateLocalCacheOnStateChange({ store, platformAPIs }) {
  const debouncedUpdate = debounce(
    () =>
      // TODO: check in future if this performs, might need to do this selectively
      setTimeout(() =>
        saveInMemoryCache(platformAPIs, MEMORY_STORAGE_KEY, filterStateForCache(store.getState())),
      ),
    200,
    { maxWait: 1000 },
  );
  store.subscribe(debouncedUpdate);
}

function doRouting({
  router,
  location,
  store,
  isInitialLoad = true,
  shouldTransformOldLink = true,
}) {
  return router
    .match(shouldTransformOldLink ? transformOldLinks(location).pathname : location.pathname)
    .then(match => isInitialLoad && store.dispatch(setRouterMatch(match)));
}

function transformOldLinks(location) {
  const pathname = decodeURIComponent(location.pathname);
  const pageQueryMatch = pathname.match(/page=([^&]*)&dl=([^&]*)/);
  const searchQueryMatch = pathname.match(/query=([^&]*)/);
  const searchQuery = location.query.query || (searchQueryMatch && searchQueryMatch[1]);
  if (location.pathname.match(/^\/search\/(?:posts|members)/) && searchQuery) {
    return makeNewLocation(location, `/search/${searchQuery}`);
  } else if (location.query.page) {
    return makeNewLocation(location, `${location.pathname}/p-${location.query.page}`);
  } else if (pageQueryMatch) {
    const url = pathname.split('?');
    return makeNewLocation(
      location,
      `${url[0]}/p-${pageQueryMatch[1]}${
        pageQueryMatch[2] ? `/dl-${pageQueryMatch[2].split('/').join('-')}` : ''
      }`,
    );
  }
  return location;
}

function makeNewLocation(location, pathname) {
  return {
    ...location,
    pathname,
  };
}

function filterStateForCache(state) {
  return {
    ...state,
    modal: { ...state.modal, openedModals: {} },
    appLoaded: { hasReported: false },
  };
}

function createLogger(isDebug, isProduction) {
  return (...args) => {
    if (!isProduction || isDebug) {
      console.log(...args);
    }
  };
}

function composeWithReduxDevTools(wixCodeApi) {
  const shouldEnable =
    wixCodeApi.location.query.debug && wixCodeApi.window.rendering.env !== 'backend';

  return shouldEnable
    ? composeWithDevTools({
        name: 'fapp',
        realtime: shouldEnable,
        port: 8000,
        suppressConnectErrors: true,
      })
    : f => f;
}

function initializeNavigationHandlerForEditor({ store, router }) {
  if (typeof window !== 'undefined') {
    window.__navigateWithinForumInternally = pathname => {
      doRouting({
        store,
        router,
        location: { pathname },
        isInitialLoad: false,
        shouldTransformOldLink: false,
      });
    };
  }
}
