import { omit, noop, flowRight, get } from 'lodash';
import {
  EXPERIMENT_RELATED_POSTS,
  EXPERIMENT_RENDER_SEO_TAGS,
  EXPERIMENT_SET_SEO_STATUS_CODE,
} from '../constants/experiments';
import {
  ROUTE_404,
  ROUTE_ACCOUNT_SUSPENDED,
  ROUTE_CATEGORY,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_COMMENT_DEEP_LINK,
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_POST,
  ROUTE_POST_EDIT,
  ROUTE_SEARCH,
  ROUTE_DEV_PLAYGROUND,
} from '../constants/routes';
import { MODAL_TYPE_CREATE_POST } from '../components/modals/post-create-modal/post-create-modal-type';
import { MODAL_TYPE_EDIT_POST } from '../components/modals/post-create-modal/post-edit-modal-type';
import { Router } from '../../common/router';
import { fetchCategory } from '../actions/fetch-category';
import { fetchSubcategories } from '../actions/fetch-categories';
import { fetchCategoryPosts } from '../actions/fetch-category-posts';
import { getCurrentUser, isBlocked } from '../../common/store/current-user/current-user-selectors';
import { getIsMobile, isSite, isSSR } from '../../common/store/basic-params/basic-params-selectors';
import { getIsMainPageEnabled } from '../selectors/app-settings-selectors';
import { openModal } from '../../common/modals/framework/store/modal-actions';
import search, { clearSearchResults, MIN_QUERY_LENGTH } from '../actions/search';
import { setIsLoading } from '../actions/set-is-loading';
import { decodeSpaces } from '../services/query-encoding';
import { fetchPost } from '../actions/fetch-post';
import { getPostBySlug } from '../selectors/post-selectors';
import {
  resolveCommentDeepLinkUrl,
  resetCommentDeepLinkUrl,
} from '../actions/comment-deep-link-actions';
import { getCommentDeepLinkRoutingData } from '../selectors/deep-link-routing-data-selectors';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { fetchPostPageData } from '../actions/fetch-post-page-data';
import { getDeepLinkParams } from '../services/get-deep-link-params';
import { fetchRepliesToPage } from '../actions/fetch-replies';
import { getCategoryBySlug, getCategories } from '../selectors/categories-selectors';
import { addErrorState } from '../../common/store/debug-state/debug-state-actions';
import { getPreviousMatches } from '../../common/router/router-selectors';
import { clearComments } from '../actions/clear-comments';
import { fetchFooterPosts } from '../actions/fetch-footer-posts';
import { fetchRelatedPosts } from '../actions/fetch-related-posts';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { getStyleParams } from '../../common/store/style-params/style-params-selectors';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import { getImageMetadataFromWixMedia, getVideoThumbnailMetadata } from '../services/image-adapter';
import { getLastPage } from '../services/pagination';
import { getCommentsPerPage, POSTS_PER_PAGE } from '../constants/pagination';
import { DISCUSSION, QUESTION } from '../constants/post-types';
import { getEntityCount } from '../selectors/pagination-selectors';
import contentStateToString from '../services/content-state-to-string';
import { getPostCover } from '../../common/services/get-post-cover';
import { buildFullTitle, getMetaTags } from '../services/metadata';
import { getForumData, getForumTitle } from '../../common/selectors/forum-data-selectors';
import { emitOpenPost } from '../actions/post-socket';
import { incrementPostViewCount } from '../actions/increment-post-view-count';
import { pageOpened } from '../actions/page-opened';
import { getIsDemoMode } from '../../common/store/instance-values/instance-values-selectors';
import { POST_PAGE, FEED_PAGE, CATEGORIES_PAGE } from '../constants/page-names';
import { emitOpenCategory } from '../actions/category-socket';
import { createHelpers, getPermissions } from '../../common/services/permission-checker';
import { isMembersOnly } from '../../common/services/category-privacy-utils';
import { getIsSearchLoading } from '../selectors/is-loading-selectors';
import { getForumSectionUrl } from '../../common/services/get-section-url';
import { buildLink } from '../../common/store/ma-navigation/build-link';
import { getProfileLinkTemplate } from '../../common/store/ma-navigation/ma-navigation-selectors';
import { getBaseUrl } from '../../common/store/location/location-selectors';

function logErrorState(store, handler) {
  return error => {
    store.dispatch(addErrorState(error));
    return handler(error);
  };
}

const assertUserLoggedInAndNotBlocked = (state, redirect) => {
  const currentUser = getCurrentUser(state);
  if (currentUser) {
    if (isBlocked(state)) {
      return { isUserValid: false, redirectState: redirect(ROUTE_ACCOUNT_SUSPENDED) };
    }
  } else {
    return { isUserValid: false, redirectState: redirect(ROUTE_LOGIN) };
  }
  return { isUserValid: true };
};

const createCreatePostRouteHandler = (store, postType) => ({}, redirect) => {
  const state = store.getState();
  const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(state, redirect);
  if (!isUserValid) {
    return redirectState;
  }
  if (getIsMobile(state)) {
    store.dispatch(openModal(MODAL_TYPE_CREATE_POST, { postType }));
  }
};

const createPostEditRouteHandler = store => ({ params }, redirect) => {
  const state = store.getState();
  const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(state, redirect);
  if (!isUserValid) {
    return redirectState;
  }
  if (getIsMobile(state)) {
    store.dispatch(openModal(MODAL_TYPE_EDIT_POST));
  }
  const post = getPostBySlug(state, params.postSlug);
  if (!post) {
    return Promise.all([
      store.dispatch(fetchCategory(params.categorySlug)),
      store.dispatch(fetchPost(params.postSlug)),
    ]);
  }
};

const createCategoryPageHandler = (store, wixCodeApi, compId, config) => (
  { params: { categorySlug, page } },
  redirect,
) => {
  if (!categorySlug) {
    // all posts pseudo category
    return store
      .dispatch(
        fetchCategoryPosts({
          categoryId: null,
          page: parseInt(page, 10) || 1,
        }),
      )
      .catch(logErrorState(store, () => redirect(ROUTE_404)));
  }
  const state = store.getState();
  const style = getStyle(config, state);
  const category = getCategoryBySlug(state, categorySlug);

  if (isSite(state)) {
    const forumTitle = getForumTitle(state);

    if (category) {
      const page_ = parseInt(page, 10) || 1;
      const entityCount = getEntityCount(state, 'posts', category._id);
      const lastPage = getLastPage(entityCount, POSTS_PER_PAGE);
      const prev = page_ > 1 ? page_ - 1 : undefined;
      const next = page_ < lastPage ? page_ + 1 : undefined;

      if (isExperimentEnabled(state, EXPERIMENT_RENDER_SEO_TAGS)) {
        const sectionUrl = getForumSectionUrl(wixCodeApi);
        wixCodeApi.seo.renderSEOTags({
          itemType: ITEM_TYPES.FORUM_CATEGORY,
          itemData: {
            category: {
              ...category,
              title: category.label,
              description: category.description,
              images: category.cover ? [getImageMetadataFromWixMedia(category.cover)] : [],
              videos: [],
              refersTo: `${sectionUrl}/${category.slug}`,
            },
            paging: {
              current: page_,
              next,
              prev,
              total: lastPage,
            },
          },
        });
      } else {
        const title = buildFullTitle({ forumTitle, title: category.label });
        wixCodeApi.site
          .setPageMetaData(
            {
              title: {
                text: title,
                addSiteName: true,
              },
              description: category.description,
              metaTags: getMetaTags({
                title,
                description: category.description,
                image: getImageMetadataFromWixMedia(category.cover),
                type: 'website',
              }),
            },
            compId,
          )
          .catch(noop);
      }
    }
  }

  let promise;

  if (category) {
    promise = Promise.resolve(category);
    store.dispatch(fetchCategory(categorySlug));
  } else {
    promise = store.dispatch(fetchCategory(categorySlug));
  }

  return promise
    .then(category => {
      const promises = [];
      if (category._id) {
        promises.push(store.dispatch(fetchSubcategories(category._id)));
      }
      const { can } = flowRight(createHelpers, getPermissions, store.getState)();
      if (can('read', 'category', category)) {
        promises.push(
          store.dispatch(
            fetchCategoryPosts({
              categoryId: category._id,
              page: parseInt(page, 10) || 1,
            }),
          ),
        );
      }

      if (!isSSR(state)) {
        const isMainPageEnabled = getIsMainPageEnabled(state, style);
        store.dispatch(
          pageOpened({
            page: FEED_PAGE,
            category,
            isMainPageEnabled,
          }),
        );
        store.dispatch(emitOpenCategory(category._id));
      }

      return Promise.all(promises);
    })
    .catch(logErrorState(store, () => redirect(ROUTE_404)));
};

const createSearchPageRouter = store => ({ params: { query } }) => {
  if (getIsSearchLoading(store.getState())) {
    // on mobile the search input itself triggers data fetch for perf optimization
    return;
  }
  store.dispatch(clearSearchResults());
  if (query && query.length >= MIN_QUERY_LENGTH) {
    store.dispatch(setIsLoading('search', '', true));
    return store.dispatch(search({ query: decodeSpaces(query) }));
  }
};

const createPostPageRouter = (store, wixCodeApi, compId) => (
  { params: { categorySlug, postSlug, page, deepLinkData } },
  redirect,
) => {
  const state = store.getState();
  const category = getCategoryBySlug(state, categorySlug);
  const prevMatches = getPreviousMatches(state);
  const isMobile = getIsMobile(state);
  const hasMovedFromDeeperPageToInitialPage = !page && prevMatches[0] && prevMatches[0].params.page;
  const fetchPostData = () => store.dispatch(fetchPostPageData(postSlug, parseInt(page, 10) || 1));

  if (
    !isMobile &&
    prevMatches[1] && //here 0-th match is the current match
    prevMatches[1].pathname.includes(`${categorySlug}/${postSlug}`)
  ) {
    return fetchPostData();
  }
  if (isMobile && hasMovedFromDeeperPageToInitialPage) {
    // user has moved from n-th page to 1st
    // since comments for mobile are always shown all that are loaded, we need to reset them so for 1st page only 1st page comments are shown
    const post = getPostBySlug(state, postSlug);
    store.dispatch(clearComments(post._id));
  }
  const { parentId, repliesPage } = getDeepLinkParams(deepLinkData);
  const shouldFetchAdditionalReplies = repliesPage && repliesPage !== 1 && parentId;
  const categoryPromise = category
    ? Promise.resolve(category)
    : store.dispatch(fetchCategory(categorySlug));
  const postPromise = fetchPostData();
  return Promise.all([
    categoryPromise,
    postPromise,
    shouldFetchAdditionalReplies
      ? store.dispatch(
          fetchRepliesToPage({
            commentId: parentId,
            postSlug,
            page: repliesPage,
          }),
        )
      : null,
    categoryPromise.then(category =>
      store.dispatch(fetchFooterPosts({ categoryId: category._id, excludeSlug: postSlug })),
    ),
    postPromise.then(({ post }) => {
      if (isExperimentEnabled(state, EXPERIMENT_RELATED_POSTS)) {
        return store.dispatch(fetchRelatedPosts(post._id));
      }
    }),
  ])
    .then(results => {
      const category = results[0];
      const post = results[1].post;
      const isCategorySlugDiffers = categorySlug !== category._id && categorySlug !== category.slug;
      const isPostSlugDiffers = postSlug !== post._id && postSlug !== post.slug;
      if (isCategorySlugDiffers || isPostSlugDiffers) {
        return store.dispatch(navigateWithinForum(`/${category.slug}/${post.slug}`));
      }

      const state = store.getState();

      const isAuthenticated = Boolean(state.currentUser);
      if (!isAuthenticated && isMembersOnly(category)) {
        return store.dispatch(navigateWithinForum(`/login?redirect=/${categorySlug}/${postSlug}`));
      }

      if (!isSSR(state) && !getIsDemoMode(state) && post._id) {
        store.dispatch(emitOpenPost(post._id));
        store.dispatch(incrementPostViewCount(post._id));
        store.dispatch(
          pageOpened({
            page: POST_PAGE,
            post,
          }),
        );
      }
    })
    .then(() => {
      const state = store.getState();
      if (!isSite(state)) {
        return;
      }

      const isMobile = getIsMobile(state);
      const forumTitle = getForumTitle(state);
      const category = getCategoryBySlug(state, categorySlug);
      if (!category) {
        return;
      }
      const post = getPostBySlug(state, postSlug);
      if (!post) {
        return;
      }
      const postCover = getPostCover(post);
      const page_ = parseInt(page, 10) || 1;
      const entityCount = getEntityCount(state, 'comments', post._id);
      const lastPage = getLastPage(entityCount, getCommentsPerPage(isMobile));
      const prev = page_ > 1 ? page_ - 1 : undefined;
      const next = page_ < lastPage ? page_ + 1 : undefined;

      const image = postCover.shouldRender
        ? postCover.videoMetadata
          ? null
          : getImageMetadataFromWixMedia(postCover.imageMetadata)
        : null;

      const video = postCover.shouldRender
        ? postCover.videoMetadata
          ? getVideoThumbnailMetadata(postCover.videoMetadata)
          : null
        : null;

      const content = contentStateToString(post.content);

      if (isExperimentEnabled(state, EXPERIMENT_RENDER_SEO_TAGS)) {
        const sectionUrl = getForumSectionUrl(wixCodeApi);
        const baseUrl = getBaseUrl(state);
        const postOwnerSiteMemberId = get(post, 'owner.siteMemberId');

        wixCodeApi.seo.renderSEOTags({
          itemType: ITEM_TYPES.FORUM_POST,
          itemData: {
            post: {
              ...omit(post, 'content'),
              title: post.title,
              description: content,
              contentText: content,
              images: image ? [image] : [],
              videos: video ? [video] : [],
              refersTo: `${sectionUrl}/${category.slug}/${post.slug}`,
            },
            category: { label: category.label },
            owner: {
              profile: {
                url: postOwnerSiteMemberId
                  ? `${baseUrl}/${buildLink(getProfileLinkTemplate(state), postOwnerSiteMemberId)}`
                  : '',
              },
            },
            paging: {
              current: page_,
              next,
              prev,
              total: lastPage,
            },
          },
        });
      } else {
        const title = buildFullTitle({ forumTitle, title: post.title });
        wixCodeApi.site
          .setPageMetaData(
            {
              title: {
                text: title,
                addSiteName: true,
              },
              description: content,
              metaTags: getMetaTags({
                title,
                description: content,
                image: image || video,
                type: 'article',
              }),
            },
            compId,
          )
          .catch(noop);
      }
    })
    .catch(
      logErrorState(store, error => {
        if (error.status === 401) {
          return redirect(ROUTE_LOGIN);
        } else {
          return redirect(ROUTE_404);
        }
      }),
    );
};

const getStyle = (config, state) => {
  const styleParams = { ...config.style.styleParams, ...getStyleParams(state) };
  return { ...config.style, styleParams };
};

const createHomeRouter = (store, config, wixCodeApi, compId) => (router, redirect) => {
  const state = store.getState();
  const style = getStyle(config, state);

  if (isSite(state)) {
    const forumData = getForumData(state);
    const title = forumData.label;
    const description = forumData.description;

    if (isExperimentEnabled(state, EXPERIMENT_RENDER_SEO_TAGS)) {
      wixCodeApi.seo.renderSEOTags();
    } else {
      wixCodeApi.site
        .setPageMetaData(
          {
            title: {
              text: title,
              addSiteName: true,
            },
            description,
            metaTags: getMetaTags({
              title,
              description,
              image: getImageMetadataFromWixMedia(forumData.cover),
              type: 'website',
            }),
          },
          compId,
        )
        .catch(noop);
    }
  }

  const isMainPageEnabled = getIsMainPageEnabled(state, style);
  if (!isSSR(state)) {
    store.dispatch(pageOpened({ page: CATEGORIES_PAGE, isMainPageEnabled }));
  }
  if (isMainPageEnabled) {
    return store.dispatch(fetchFooterPosts());
  }

  const categories = getCategories(state);
  if (categories.length === 1) {
    return redirect(`/${categories[0].slug}`);
  }

  return createCategoryPageHandler(store, wixCodeApi, compId)(router, redirect);
};

const createCommentDeepLinkRouter = store => ({ params }) => {
  const state = store.getState();
  const ssr = isSSR(state);
  const routingData = getCommentDeepLinkRoutingData(state);

  if (routingData) {
    store.dispatch(navigateWithinForum(routingData));
    store.dispatch(resetCommentDeepLinkUrl());
  } else {
    store.dispatch(resolveCommentDeepLinkUrl(params.commentId, true)).then(url => {
      if (!ssr) {
        store.dispatch(navigateWithinForum(url));
        store.dispatch(resetCommentDeepLinkUrl());
      }
    });
  }
};

const create404PageHandler = store => () => {
  if (isExperimentEnabled(store.getState(), EXPERIMENT_SET_SEO_STATUS_CODE)) {
    self['wix-seo'].setSeoStatusCode(404);
  }
};

export const createRouter = (store, config, wixCodeApi, compId) => {
  const router = new Router();
  router.add(ROUTE_404, create404PageHandler(store));
  if (process.env.NODE_ENV === 'development') {
    router.add(ROUTE_DEV_PLAYGROUND);
  }
  router.add(ROUTE_SEARCH, createSearchPageRouter(store));
  router.add(ROUTE_LOGIN);
  router.add(ROUTE_ACCOUNT_SUSPENDED);
  router.add(ROUTE_CREATE_POST, createCreatePostRouteHandler(store, DISCUSSION));
  router.add(ROUTE_CREATE_QUESTION, createCreatePostRouteHandler(store, QUESTION));
  router.add(ROUTE_CATEGORY_CREATE_POST, createCreatePostRouteHandler(store, DISCUSSION));
  router.add(ROUTE_CATEGORY_CREATE_QUESTION, createCreatePostRouteHandler(store, QUESTION));
  router.add(ROUTE_POST_EDIT, createPostEditRouteHandler(store));
  router.add(ROUTE_COMMENT_DEEP_LINK, createCommentDeepLinkRouter(store));
  router.add(ROUTE_HOME, createHomeRouter(store, config, wixCodeApi, compId));
  router.add(ROUTE_CATEGORY, createCategoryPageHandler(store, wixCodeApi, compId, config));
  router.add(ROUTE_POST, createPostPageRouter(store, wixCodeApi, compId));
  router.fallback(ROUTE_404);
  return router;
};
